import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const AppSection = () => {
  const data = useStaticQuery(graphql`
    query {
      starIcon: file(relativePath: { eq: "home/icon-star.svg" }) {
        publicURL
      }
      appStoreIcon: file(relativePath: { eq: "icons/app-store.svg" }) {
        publicURL
      }
      googlePlayIcon: file(relativePath: { eq: "icons/google-play.svg" }) {
        publicURL
      }
      appStoreDesktop: file(
        relativePath: { eq: "icons/appstore-desktop.svg" }
      ) {
        publicURL
      }
      googlePlayDesktop: file(
        relativePath: { eq: "icons/googleplay-desktop.svg" }
      ) {
        publicURL
      }
    }
  `)

  return (
    <section className="section section-app">
      <div className="container">
        <h2 className="title">
          <span className="has-text-white">Tudo</span>{" "}
          <span className="has-text-blue">
            em <br />
            um só lugar
          </span>
        </h2>

        <p className="subtitle">
          Tenha fácil acesso a diversos serviços para o seu veículo, em um só
          aplicativo:
        </p>

        <ul className="features-list">
          <li className="features-item">Estacionamento rotativo</li>
          <li className="features-item">Tag de pedágio sem filas</li>
          <li className="features-item">Valor de mercado</li>
          <li className="features-item">Parcelamento de multas e IPVA</li>
          <li className="features-item">Rodízio de veículos</li>
          <li className="features-item">Estacionamento de shopping</li>
          <li className="features-item">Seguro Auto</li>
          <li className="features-item">Concessionárias próximas</li>
        </ul>

        <div className="is-hidden-desktop">
          <p className="has-text-centered">
            <a className="button is-rounded is-large blue-cta" href="/zul-mais">
              Conheça o app
            </a>
          </p>
        </div>

        <div className="columns is-vcentered is-hidden-mobile">
          <div className="column is-half">
            <div className="reviews">
              <div className="is-clearfix has-text-centered">
                <ul className="reviews-stars is-clearfix">
                  <li className="reviews-star is-pulled-left">
                    <img src={data.starIcon.publicURL} alt="Estrela" />
                  </li>
                  <li className="reviews-star is-pulled-left">
                    <img src={data.starIcon.publicURL} alt="Estrela" />
                  </li>
                  <li className="reviews-star is-pulled-left">
                    <img src={data.starIcon.publicURL} alt="Estrela" />
                  </li>
                  <li className="reviews-star is-pulled-left">
                    <img src={data.starIcon.publicURL} alt="Estrela" />
                  </li>
                  <li className="reviews-star is-pulled-left">
                    <img src={data.starIcon.publicURL} alt="Estrela" />
                  </li>
                </ul>
              </div>
              <p className="reviews-p">
                + de 80 mil <span>avaliações</span>
              </p>
              <div className="columns is-gapless">
                <div className="column has-text-right">
                  <br />
                  <a
                    href="https://apps.apple.com/br/app/zul-zona-azul-digital-cet-sp/id1188772766"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={data.appStoreIcon.publicURL}
                      className="is-hidden-desktop"
                      width="149"
                      height="41"
                      alt="Apple App Store"
                    />
                    <img
                      src={data.appStoreDesktop.publicURL}
                      className="hidden-mobile"
                      style={{
                        marginRight: "11px",
                      }}
                      width="160"
                      height="48"
                      alt="Apple App Store"
                    />
                  </a>
                </div>
                <div className="column has-text-left">
                  <br />
                  <a
                    href="https://play.google.com/store/apps/details?id=br.com.zuldigital"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={data.googlePlayIcon.publicURL}
                      className="is-hidden-desktop"
                      width="149"
                      height="41"
                      alt="Google Play"
                    />
                    <img
                      src={data.googlePlayDesktop.publicURL}
                      width="160"
                      height="48"
                      alt="Google Play"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-half has-text-right">
            <a className="button is-rounded is-large blue-cta" href="/zul-mais">
              Conheça o APP
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AppSection
